import React from "react";
import './EqusuppInfo.css'

function EqusuppInfo() {
  return (
    <div>
      <section className="equsuppinfo-section">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 col-sm-12 equsuppinfo-cont">
              <h3>A HUGE VARIETY OF FACILITIES & EQUIPMENT FOR THE ATM’s</h3>
              <p>
                We at MBE ATM provide a huge variety of facilities and
                approximately all the equipment needed for the ATM’s. We are
                stocked up with the required inventory for the smooth running of
                the parts and supplies of your ATM needs.
              </p>
              <p>
                MBE ATMs commitment to excellent service, network reliance and
                reliability as well as user and client security is guaranteed.
                This is in addition to the provision of most innovative, robust
                as well as cost effective ATMs in the market for unified success
                at both ends.
              </p>
              <h6>Explore your business opportunities with MBE ATM Today!</h6>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default EqusuppInfo;
