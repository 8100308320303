import React from "react";
import "./AtmSaleRent.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

function AtmSaleRent() {
  return (
    <div>
      <section className="atmsalerent-section">
        <div className="container srheadings">
          <div className="row">
            <div className="col-md-12 col-sm-12 srhedng">
              <h1>ATM machines for SALE or RENTAL</h1>
            </div>
            <div className="col-md-12 col-sm-12">
              <p>MBE Atm provides services in</p>
            </div>
          </div>
        </div>
      </section>
      <section className="city-sec">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-4 col-sm-12 atmsalerent-cont">
              <ul>
                <li>
                  <div className="flex text-6xl">
                    <FontAwesomeIcon
                      size="lg"
                      className="licon"
                      icon={faCheckCircle}
                    />
                  </div>
                  <p>Ontario</p>
                </li>
                <li>
                  <div className="flex text-6xl">
                    <FontAwesomeIcon
                      size="lg"
                      className="licon"
                      icon={faCheckCircle}
                    />
                  </div>
                  <p>Hamilton</p>
                </li>
                <li>
                  <div className="flex text-6xl">
                    <FontAwesomeIcon
                      size="lg"
                      className="licon"
                      icon={faCheckCircle}
                    />
                  </div>
                  <p>Ottawa</p>
                </li>
                <li>
                  <div className="flex text-6xl">
                    <FontAwesomeIcon
                      size="lg"
                      className="licon"
                      icon={faCheckCircle}
                    />
                  </div>
                  <p>London</p>
                </li>
                <li>
                  <div className="flex text-6xl">
                    <FontAwesomeIcon
                      size="lg"
                      className="licon"
                      icon={faCheckCircle}
                    />
                  </div>
                  <p>Kitchener</p>
                </li>
                <li>
                  <div className="flex text-6xl">
                    <FontAwesomeIcon
                      size="lg"
                      className="licon"
                      icon={faCheckCircle}
                    />
                  </div>
                  <p>St.Catharines</p>
                </li>
              </ul>
            </div>
            <div className="col-md-4 col-sm-12 atmsalerent-cont">
              <ul>
                <li>
                  <div className="flex text-6xl">
                    <FontAwesomeIcon
                      size="lg"
                      className="licon"
                      icon={faCheckCircle}
                    />
                  </div>
                  <p>Stoney Creek</p>
                </li>
                <li>
                  <div className="flex text-6xl">
                    <FontAwesomeIcon
                      size="lg"
                      className="licon"
                      icon={faCheckCircle}
                    />
                  </div>
                  <p>Niagara Falls (region)</p>
                </li>
                <li>
                  <div className="flex text-6xl">
                    <FontAwesomeIcon
                      size="lg"
                      className="licon"
                      icon={faCheckCircle}
                    />
                  </div>
                  <p>Cambriage</p>
                </li>
                <li>
                  <div className="flex text-6xl">
                    <FontAwesomeIcon
                      size="lg"
                      className="licon"
                      icon={faCheckCircle}
                    />
                  </div>
                  <p>Brantford</p>
                </li>
                <li>
                  <div className="flex text-6xl">
                    <FontAwesomeIcon
                      size="lg"
                      className="licon"
                      icon={faCheckCircle}
                    />
                  </div>
                  <p>Burlington</p>
                </li>
                <li>
                  <div className="flex text-6xl">
                    <FontAwesomeIcon
                      size="lg"
                      className="licon"
                      icon={faCheckCircle}
                    />
                  </div>
                  <p>Mississauga</p>
                </li>
              </ul>
            </div>
            <div className="col-md-4 col-sm-12 atmsalerent-cont">
              <ul>
                <li>
                  <div className="flex text-6xl">
                    <FontAwesomeIcon
                      size="lg"
                      className="licon"
                      icon={faCheckCircle}
                    />
                  </div>
                  <p>Oakville</p>
                </li>
                <li>
                  <div className="flex text-6xl">
                    <FontAwesomeIcon
                      size="lg"
                      className="licon"
                      icon={faCheckCircle}
                    />
                  </div>
                  <p>Toronto</p>
                </li>
                <li>
                  <div className="flex text-6xl">
                    <FontAwesomeIcon
                      size="lg"
                      className="licon"
                      icon={faCheckCircle}
                    />
                  </div>
                  <p>Ancaster</p>
                </li>
                <li>
                  <div className="flex text-6xl">
                    <FontAwesomeIcon
                      size="lg"
                      className="licon"
                      icon={faCheckCircle}
                    />
                  </div>
                  <p>Guelph</p>
                </li>
                <li>
                  <div className="flex text-6xl">
                    <FontAwesomeIcon
                      size="lg"
                      className="licon"
                      icon={faCheckCircle}
                    />
                  </div>
                  <p>Cambriage</p>
                </li>
                <li>
                  <div className="flex text-6xl">
                    <FontAwesomeIcon
                      size="lg"
                      className="licon"
                      icon={faCheckCircle}
                    />
                  </div>
                  <p>London</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default AtmSaleRent;
