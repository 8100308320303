import React from "react";
import './TransprosTag.css'
import { Link } from "react-router-dom";


function TransprosTag() {
  return (
    <div>
      <section>
        <div className="container-fluid transproc-section">
          <div className="row">
            <div className="col-sm-12 col-md-12 transproc-cont">
              <p>
                <Link className="gohome" to="/">
                  Home
                </Link>{" "}
                / Transaction Processing
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default TransprosTag;