import React, { Component } from 'react'
import CashmanageBanner from '../CashmanageBanner'
import CashmanagTag from '../CashmanagTag'
import CashmanagTitle from '../CashmanagTitle'
import Footer from '../Footer'
import Howitworks from '../Howitworks'

class CashManagement extends Component {
    render() {
        return (
            <div>
                <CashmanagTag />
                <CashmanagTitle />
                <CashmanageBanner />
                <Howitworks />
                <Footer />
            </div>
        )
    }
}

export default CashManagement