import React, { Component } from 'react'
import TelecomBanner from '../TelecomBanner'
import TelecomInfo from '../TelecomInfo'
import TelecomTag from '../TelecomTag'
import TelecomTitle from '../TelecomTitle'
import Footer from '../Footer'

class Telecommunicatuon extends Component {
    render() {
        return (
            <div>
                <TelecomTag />
                <TelecomTitle />
                <TelecomBanner />
                <TelecomInfo />
                <Footer />   
            </div>
        )
    }
}

export default Telecommunicatuon