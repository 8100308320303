import {
  faAddressCard,
  faEnvelope,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";
import "./Footer.css";

function Footer() {
  return (
    <div>
      <section className="footer-section">
        <div className="container footer-content">
          <div className="row">
            <div className="col-md-4 footer-about">
              <Link to="/">
                <img
                  className="footer-logo"
                  src="./image/atm-logo-252.png"
                  alt="..."
                />
              </Link>
              <p>
                MBE is an independent, Canada based business solutions and
                services providing group that is envisioned to lead the industry
                through trend-setting innovation and ground-breaking ideas.
              </p>
              <img
                className="footer-cards"
                src="./image/footer-cards2.png"
                alt="..."
              />
            </div>
            <div className="col-md-2 footer-ulinks">
              <h3>USEFUL LINKS</h3>
              <p>
                <a
                  href="https://pos.mbeforyou.com/ca"
                  rel="noreferrer"
                  target="_blank"
                  className="ulinks"
                >
                  MBE POS
                </a>
              </p>
              <p>
                <a
                  href="https://global.mbeforyou.com/"
                  rel="noreferrer"
                  target="_blank"
                  className="ulinks"
                >
                  MBE GLOBAL
                </a>
              </p>
              <p>
                <a
                  href="https://magazine.mbeforyou.com/"
                  rel="noreferrer"
                  target="_blank"
                  className="ulinks"
                >
                  MBE MAGAZINE
                </a>
              </p>
              <p>
                <a
                  href="https://blog.mbeforyou.com/"
                  rel="noreferrer"
                  target="_blank"
                  className="ulinks"
                >
                  MBE BLOG
                </a>
              </p>
            </div>
            <div className="col-md-3 footer-slinks">
              <h3>SERVICES</h3>
              <p>
                <a
                  href="/purchase-options"
                  target="_blank"
                  rel="noreferrer"
                  className="ulinks"
                >
                  PURCHASE OPTIONS
                </a>
              </p>
              <p>
                <a
                  href="/placement-modes"
                  target="_blank"
                  rel="noreferrer"
                  className="ulinks"
                >
                  PLACEMENT MODEL
                </a>
              </p>
              <p>
                <a
                  href="/maintenance"
                  target="_blank"
                  rel="noreferrer"
                  className="ulinks"
                >
                  MAINTENANCE
                </a>
              </p>
              <p>
                <a
                  href="/cash-management"
                  target="_blank"
                  rel="noreferrer"
                  className="ulinks"
                >
                  CASH MANAGEMENT
                </a>
              </p>
              <p>
                <a
                  href="/transaction-processing"
                  target="_blank"
                  rel="noreferrer"
                  className="ulinks"
                >
                  TRANSACTION PROCESS
                </a>
              </p>
            </div>
            <div className="col-md-3 footer-address">
              <h3>CONTACT INFO</h3>
              <p>
                <FontAwesomeIcon icon={faAddressCard} /> MBE – 104-100 Dynamic
                Drive, Toronto, ON MIV 5C4
              </p>
              <p>
                <FontAwesomeIcon icon={faPhone} className="fico" />
                <a href="tel:1-866-667-1377" className="ulinks">
                  1-866-667-1377
                </a>
              </p>
              <p>
                <FontAwesomeIcon icon={faPhone} className="fico" />
                <a href="tel:1-866-204-6888" className="ulinks">
                  1-866-204-6888
                </a>
              </p>
              <p>
                <FontAwesomeIcon icon={faEnvelope} className="fico" />
                <a href="mailto:info@mbeforyou.com" className="ulinks">
                  info@mbeforyou.com
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid crights-section">
          <div className="row">
            <div className="col-md-6 crights">
              <p>
                Ⓒ 2000 | All rights reserved | Designed by{" "}
                <a
                  className="mdlink"
                  href="https://digital.mbeforyou.com/ "
                  rel="noreferrer"
                  target="_blank"
                >
                  MBE Digital
                </a>
              </p>
            </div>
            <div className="col-md-6 cricons">
              <a
                href="https://www.facebook.com/mbecanada/"
                rel="noreferrer"
                target="_blank"
              >
                <img
                  className="icans"
                  src="./image/facebook.png"
                  alt="facebook"
                />
              </a>
              <a
                href="https://twitter.com/mbecanada/"
                rel="noreferrer"
                target="_blank"
              >
                <img className="icans" src="./image/twitter.png" alt="..." />
              </a>
              <a
                href="https://www.instagram.com/mbecanada/"
                rel="noreferrer"
                target="_blank"
              >
                <img className="icans" src="./image/instagram.png" alt="..." />
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;
