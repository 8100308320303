import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import "./PrchoptContent.css";

function PrchoptContent() {
  return (
    <div>
      <section className="prchoptcont-section">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6 col-sm-12 pos-image">
              <img className='purchaset' src="./image/free-placements-1.jpg" alt="freeplacements" />
            </div>
            <div className="col-md-6 col-sm-12 purchase-image">
              <img className='purchase' src="./image/purchase.jpg" alt="purchase" />
            </div>
          </div>
        </div>
      </section>
      <section className="prchoptcont-section">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 col-sm-12 cont">
                <h1>On purchase of New or Refurbished ATM, MBE ATM offers</h1>
                <ul>
                <li>
                  <div className="flex text-6xl">
                    <FontAwesomeIcon
                      size="lg"
                      className="licon"
                      icon={faCheckCircle}
                    />
                  </div>
                  <p>
                    No Installatin Charges
                  </p>
                </li>
                <li>
                  <div className="flex text-6xl">
                    <FontAwesomeIcon
                      size="lg"
                      className="licon"
                      icon={faCheckCircle}
                    />
                  </div>
                  <p>
                    10 Year Service & Maintenance
                  </p>
                </li>
                <li>
                  <div className="flex text-6xl">
                    <FontAwesomeIcon
                      size="lg"
                      className="licon"
                      icon={faCheckCircle}
                    />
                  </div>
                  <p>
                    5 Year Parts Wsrranty
                  </p>
                </li>
                <li>
                  <div className="flex text-6xl">
                    <FontAwesomeIcon
                      size="lg"
                      className="licon"
                      icon={faCheckCircle}
                    />
                  </div>
                  <p>
                    24/7 Customer Support
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default PrchoptContent;
