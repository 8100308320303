import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";
import Cform from "./Cform";
import "./ContactSec.css";

function ContactSec() {
  return (
    <div>
      <section className="cantact-section">
        <div className="container-fluid contact-contect">
          <div className="row">
            <div className="col-md-4 col-sm-12 contact-banner">
              <h2>Call Us</h2>
              <p>
                Give us a call today to find more information on ATM placement.
                And any authorized agent will contact you with regards to your
                questions and queries.
              </p>
              <Link>
              <button className='callnow'><FontAwesomeIcon className='phone' icon={faPhone}></FontAwesomeIcon> 1-866-667-1377</button>
              </Link>
              <img className='contact-us' src='./image/contact-us.png' alt='contactus' />
            </div>
            <div className="col-md-8 col-sm-12 contact-form">
                  <Cform />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default ContactSec;
