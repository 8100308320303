import React from "react";
import "./TransprosTitle.css";

function TransprosTitle() {
  return (
    <div>
      <section className="transpros-title-section">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6 col-sm-12 transpros-title-cont">
              <h2>TRANSACTION PROCESSING</h2>
              <h4>Procedures that you can easily rely upon</h4>
              <p>
                With MBE ATM it’s our responsibility that we give salient
                transaction processing which means greater profitability for
                your ATM. With MBE ATM, the transaction processing systems are
                managed by TNS, with their personal modern and techno friendly
                switch. For the sake of effective and reliable payment
                processing and secure self-service banking solutions, while
                delivering the integrated solutions. Deserving worldwide
                customer trust, the switch is supported by a secure and highly
                stable platform.
              </p>
              <p>
                Utilization of MBE ATM for the sake of cash management and cash
                loading. A unique feature that reduces the time that needs to be
                dedicated to ATM handling and also allows the client’s focus on
                different business priorities.
              </p>
            </div>
            <div className="col-md-6 col-sm-12 transpros-title-imag">
              <img
                className="transactionprocessing"
                src="./image/transaction processing 1.jpg"
                alt="transactionprocessing"
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default TransprosTitle;
