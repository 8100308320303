import React from "react";
import "./PlacmodCont.css";

function PlacmodCont() {
  return (
    <div>
      <section className="placmod-section">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 col-sm-12 pmheading">
              <h1>
                We offer free service and maintenance with both<br />placement modes
              </h1>
              <div className="pmunderline mb-3"></div>
            </div>
          </div>
        </div>
      </section>
      <section className="placmod-section">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6 col-sm-12 pmcontent">
              <img
                className="placmod-img"
                src="./image/full-placement.jpg"
                alt="full-placement"
              />
              <h2>Full Placement</h2>
              <p>
                Let MBE ATM team take care of everything for you, including cash
                management. You never have to worry about anything related to
                the ATM.
              </p>
            </div>
            <div className="col-md-6 col-sm-12 pmcontent">
              <img
                className="placmod-img"
                src="./image/partial-placement.jpg"
                alt="partial-placement"
              />
              <h2>Partial Placement</h2>
              <p>
                With a partial placement program, all you have to do is keep the
                ATM machine filled with Cash. Any amount that customers withdraw
                is automatically re- deposited into your account and you have
                the right to claim full surcharge.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default PlacmodCont;
