import React from "react";
import Form from "./Form";
import "./FreePlacement.css";

function FreePlacement() {
  return (
    <div>
      <section className="placement-section">
        <div className="container">
          <div className="row">
            <div className="col-md-6 fpcontent">
              <h1>FREE PLACEMENTS</h1>
              <p>
                Our free placement program is based on eligibility, if your
                location passes the site-survey. Apply for a free ATM placement
                with 24/7 Support and Service and increase traffic to your site
                without paying a thing!
              </p>
            </div>
            <div className="col-md-6">
              <div className="card">
                <div className="card-body">
                  <Form />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default FreePlacement;
