import React from "react";
import './TelecomTag.css'
import { Link } from "react-router-dom";

function TelecomTag() {
  return (
    <div>
      <section className='telecom-section'>
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12 col-md-12 telecom-cont">
              <p>
                <Link className="gohome" to="/">
                  Home
                </Link>{" "}
                / Telecommunicatuon
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default TelecomTag;
