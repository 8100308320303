import React, { Component } from 'react'
import Footer from '../Footer'
import TargmrktBanner from '../TargmrktBanner'
import TrgtmrktInfo from '../TrgtmrktInfo'
import TrgtmrktTag from '../TrgtmrktTag'
import TrgtmrktTitle from '../TrgtmrktTitle'

class TargetMarketing extends Component {
    render() {
        return (
            <div>
                <TrgtmrktTag />
                <TrgtmrktTitle />
                <TargmrktBanner />
                <TrgtmrktInfo />
                <Footer />
            </div>
        )
    }
}

export default TargetMarketing