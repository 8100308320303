import React from "react";
import './CashmanagTag.css'
import { Link } from "react-router-dom";


function CashmanagTag() {
  return (
    <div>
      <section>
        <div className="container-fluid cashmanage-section">
          <div className="row">
            <div className="col-sm-12 col-md-12 cashmanage-cont">
              <p>
                <Link className="gohome" to="/">
                  Home
                </Link>{" "}
                / Cash Management
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default CashmanagTag;
