import React from "react";
import './MaintenTag.css'
import { Link } from "react-router-dom";

function MaintenTag() {
  return (
    <div>
      <section>
        <div className="container-fluid mainten-tag-section">
          <div className="row">
            <div className="col-sm-12 col-md-12 mainten-tags-cont">
              <p>
                <Link className="gohome" to="/">
                  Home
                </Link>{" "}
                / Maintenance
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default MaintenTag;
