import React from "react";
import './MaintenWarranty.css'

function MaintenWarranty() {
  return (
    <div>
      <section className='matinten-waranty-section'>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 col-sm-12 matinten-waranty-cont">
              <h3>ALWAYS THERE WITH TRUST – COMPREHENSIVE WARRANTY COVERAGE</h3>
              <p>
                At MBE ATM, the Comprehensive Warranty Coverage makes sure that
                any faults at the client side with ATM’s is either repaired or
                replaced fast and effectively. The maintenance professional team
                ensures quality assurance of all parts and that they are genuine
                factory parts to guarantee ATM repairs. For quick response we
                have parts stored in our warehouses for client’s random request
                of 1-2 days.
              </p>
              <p>
                With MBE ATM’s, we guarantee you the most reliable ATM service
                in the market, and we guarantee quick response if something goes
                wrong at the client side.
              </p>
              <h6>
                CONTACT MBE ATM For most trusted ATM Equipment & service and
                24/7 customer support.
              </h6>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default MaintenWarranty;
