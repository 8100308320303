import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import './App.css';
import Navbar from './components/Navbar';
import About from './components/pages/About';
import CashManagement from './components/pages/CashManagement';
import Contact from './components/pages/Contact';
import EquipmentSupplies from './components/pages/EquipmentSupplies';
import Home from './components/pages/Home';
import Maintenance from './components/pages/Maintenance';
import PlacementModes from './components/pages/PlacementModes';
import PlacementOpportunities from './components/pages/PlacementOpportunities';
import PurchaseOptions from './components/pages/PurchaseOptions';
import TargetMarketing from './components/pages/TargetMarketing';
import Telecommunicatuon from './components/pages/Telecommunicatuon';
import TransactionProcessing from './components/pages/TransactionProcessing';
import TopNav from './components/TopNav';

function App() {
  return (
    <Router>
      <TopNav />
      <Navbar />
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/about" component={About} />
        <Route path="/contact" component={Contact} />
        <Route path="/placement-opportunities" component={PlacementOpportunities} />
        <Route path="/purchase-options" component={PurchaseOptions} />
        <Route path="/placement-modes" component={PlacementModes} />
        <Route path="/maintenance" component={Maintenance} />
        <Route path="/cash-management" component={CashManagement} />
        <Route path="/transaction-processing" component={TransactionProcessing} />
        <Route path="/equipment-supplies" component={EquipmentSupplies} />
        <Route path="/telecommunicatuon" component={Telecommunicatuon} />
        <Route path="/target-marketing" component={TargetMarketing} />
      </Switch>
    </Router>
  );
}

export default App;
