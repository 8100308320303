import React from "react";
import "./Howitworks.css";

function Howitworks() {
  return (
    <div>
      <section className="howitworks-section">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 col-sm-12 howitworks-cont">
              <h3>HOW IT WORKS FOR MONEY MANAGEMENT?</h3>
              <p>
                MBE ATM’s money management systems is highly advanced and
                according to the market requirements. Initially it applies
                predictive modelling based on the past cash dispensing history.
                For the true projection of the cash needs of the ATM based on
                week days and upcoming holidays and events.
              </p>
              <p>
                Our MBE ATM team dedicated for cash management confirms the
                daily client orders for cash and audits the delivery of money to
                armored vehicles and ATMs. Our monitoring team is dedicated to
                monitor the balances of cash orders and audits costs for armored
                vehicles and cash providers. MBE ATM has effectively transformed
                the guesswork for money management in an easy and useful
                formula.
              </p>
              <p>
                Our ATMs provided are more than reliable as we supply the
                resources and support that will maximize the client’s
                profitability potentials.
              </p>
              <h6>
                CONTACT ASAP, IF you have an understanding of our cash
                Management system, and we shall be obliged to serve you. Call us
                now for an Easy Deployment of ATM at your business.
              </h6>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Howitworks;
