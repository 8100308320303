import React from 'react'
import emailjs from 'emailjs-com'
import ReCAPTCHA from "react-google-recaptcha";
import './Cform.css'

    function handleOnChange(value) {
        console.log("Captcha value:", value);
      }
    
    function sendEmail(e) {
        e.preventDefault();
    
    emailjs.sendForm('service_etl8omw', 'template_4cvgl4c', e.target, 'user_iqrTrz3C95GwVNLxy507l')
        .then(res => {
            console.log(res);
            if (res.text){
                document.getElementById("success").innerHTML = "Thnaks, You have submited successfully!!";
            }
        }).catch(err => {
            console.log(err)
            if (err.text){
                document.getElementById("unsuccess").innerHTML = "Sorry, There was some problem sumitting the Form!!";
            }
        });
        e.target.reset()
        
    }


function Cform() {
    return (
        <div className='container'>
           <form id="serviceForm" onSubmit={sendEmail}>
                    <div className="cform">
                        <input type="text" className="cfields" placeholder="First name" name="fname" pattern="[A-Za-z]+" required /><br/>
                        <input type="text" className="cfields" placeholder="Last name" name="lname" pattern="[A-Za-z]+" required /><br/>
                        <input type="tel" className="cfields" placeholder="Enter Your Contact" name="number" pattern="[0-9]{2}[0-9]{3}[0-9]{4}" required /><br/>
                        <input type="email" className="cfields" placeholder="Enter Your Email" name="email" required /><br/>
                        <input type="text" className="cfields" placeholder="Subject" name="subject" pattern="[A-Za-z]+" required /><br/>
                        <textarea className="comments" placeholder="Write Something" name="comments" rows="10" cols="50"/>
                        <ReCAPTCHA sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI" onChange={handleOnChange} />
                        <input type="submit" className="cbutton" value="SUBMIT" name="submit" ></input>
                        <div className="sucscont">
                            <p id="success"></p>
                        </div>
                        <div className="unsucscont">
                            <p id="unsuccess"></p>
                        </div> 
                    </div>
                </form>
 
        </div>
    )
}

export default Cform
