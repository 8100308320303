import React from "react";
import "./TopNav.css";

function TopNav() {
  return (
    <div className="topnv">
      <nav className="navbaar navbar-expand-lg sticky-top container">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6 col-sm-12 colmf">
              <p>Call Us Today!  <a href="tel:1.866.667.1377" className='tnlinks'>1.866.667.1377</a> | <a href="mailto:1.866.667.1377"className='tnlinks'>info@mbeforyou.com</a></p>
            </div>
            <div className="col-md-6 col-sm-12 tnicons">
              <a href="https://www.facebook.com/mbecanada/" rel='noreferrer' target='_blank'>
                <img className="icons" src="./image/facebook.png" alt="..." />
              </a>
              <a href="https://twitter.com/mbecanada/" rel='noreferrer' target='_blank'>
                <img className="icons" src="./image/twitter.png" alt="..." />
              </a>
              <a href="https://instagram.com/mbecanada/" rel='noreferrer' target='_blank'>
                <img className="icons" src="./image/instagram.png" alt="..." />
              </a>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default TopNav;
