import React, { Component } from 'react'
import Footer from '../Footer'
import PlacmodCont from '../PlacmodCont'
import PlacmodTag from '../PlacmodTag'

class PlacementModes extends Component {
    render() {
        return (
            <div>
                <PlacmodTag />
                <PlacmodCont />
                <Footer />
            </div>
        )
    }
}

export default PlacementModes