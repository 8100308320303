import React from "react";
import "./TelecomTitle.css";

function TelecomTitle() {
  return (
    <div>
      <section className="telecom-title-section">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6 col-sm-12 telecom-title-cont">
              <h2>TELECOMMUNICATIONS</h2>
              <h4>
                Variance in Solutions from MBE ATM ranging from Telecom to
                Support
              </h4>
              <p>
                MBE ATM’s ability to give instant response to their client needs
                while developing flexible solutions is outstanding. It further
                deals effectively with aspects related to telecommunications.
                MBE ATM can provide the best solutions for your business needs
                ranging from dial-up on a standard phone line to wireless
                communications. We can also incorporate with your existing
                network over DirectIP.
              </p>
              <p>
                Our team of experienced technicians at MBE ATM can easily setup
                any client for success. Includes selection of ATM, effective
                marketing, & customized telecommunications.
              </p>
            </div>
            <div className="col-md-6 col-sm-12 telecom-title-imag">
              <img
                className="telecommunications"
                src="./image/telecommunications.jpg"
                alt="telecommunications"
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default TelecomTitle;
