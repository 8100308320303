import React from "react";
import "./AboutCompany.css";

function AboutCompany() {
  return (
    <div>
      <section className="about-company-section">
        <div className="container-fluid about-company-content">
          <div className="row">
            <div className="col-md-12 col-sm-12 main-logo">
              <img className="mbepng" src="./image/mbe.png" alt="mbe" />
            </div>
            <div className="col-md-12 col-sm-12 acheading">
              <h1>MBE GROUP OF COMPANIES</h1>
              <div className="acunderline mb-3"></div>
            </div>
            <div className="col-md-12 col-sm-12 acinfo">
              <p>
                MBE was established in 1996 and has been operating in the field
                of E-commerce since twenty four years with a group of sixteen
                companies consisting of a diverse set of businesses. In Fintech
                we are specialists in ATM and POS working as MBE ATM & MBE POS.
                MBE ATM is operating all over Canada with thousands of ATM
                Machines all over the Country.
              </p>
              <p>
                The company also deals in RENT PURCHASE FREE PLACEMENT Rent out
                or lease an ATM your store at minimum rates and take advantage
                of claiming full Surcharge Purchase. The latest ATM through MBE
                ATM a Direct distributor of Hysoung at and Triton at the lowest
                and most convenient rates.
              </p>
              <p>
                Place a free ATM today at your business location with absolutely
                no investment, and earn profits through surcharge split. Our
                other businesses includes MBE insurance, MBE Accounting, MBE
                Mortgage and we also deal in IT as MBE Global. MBE also has a
                huge presence in the hotel industry as MBE Hospitality industry,
                in Magazine as MBE Business Magazine and MBE Digital for our
                Digital Marketing services. We are also there in immigration
                industry as MBE Immigration & finally RMA/MBBP. The diversity we
                have in our businesses makes us more dynamic and competitive in
                the business environment in Canada to any other similar business
                in e-commerce industry.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default AboutCompany;
