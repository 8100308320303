import React from "react";
import './ContactTag.css'
import { Link } from "react-router-dom";


function ContactTag() {
  return (
    <div>
      <section>
        <div className="container-fluid contact-tag-section">
          <div className="row">
            <div className="col-sm-12 col-md-12 contact-tags-cont">
              <p>
                <Link className="gohome" to="/">Home</Link> / Contact Us</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default ContactTag;
