import React from "react";
import "./TrgtmrktTitle.css";

function TrgtmrktTitle() {
  return (
    <div>
      <section className="trgtmrkt-title-section">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6 col-sm-12 trgtmrkt-title-cont">
              <h2>TARGET MARKETING</h2>
              <h4>
                Responsive marketing strategies designed for client’s/customer’s
                attention
              </h4>
              <p>
                Customers are transfixed on an ATM screen for each withdrawal at
                an ATM machine. Upon completion of a business transaction or
                withdrawal of cash, at MBE ATM we provide a marketing/discount
                coupon. Providing coupons, an effective way of increasing the
                amount of money during Cash handling and Cash management.
              </p>
              <p>
                MBE ATM gives you an ideal opportunity to take advantage for
                targeted marketing.
              </p>
            </div>
            <div className="col-md-6 col-sm-12 trgtmrkt-title-imag">
              <img
                className="targetmarketing"
                src="./image/target-marketing.jpg"
                alt="target-marketing.jpg"
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default TrgtmrktTitle;
