import React from "react";
import "./Carousel.css";

function Carousl() {
  return (
    <div className='crsl-section'>
      <section>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12 crslheading'>
              <h1>Our Featured Clients</h1>
              <div className='crslunderline'></div>
            </div>
          </div>
        </div>
      </section>
      <div
        id="carouselExampleControls"
        class="carousel slide"
        data-bs-ride="carousel"
      >
        <div class="carousel-inner">
          <div class="carousel-item active">
            <img src="./image/shell.png" class="imag" alt="..." />
          </div>
          <div class="carousel-item">
            <img src="./image/Arcane.png" class="imag" alt="..." />
          </div>
          <div class="carousel-item">
            <img src="./image/days-inn.png" class="imag" alt="..." />
          </div>
          <div class="carousel-item">
            <img src="./image/daisy-mart.png" class="imag" alt="..." />
          </div>
          <div class="carousel-item">
            <img src="./image/senator.png" class="imag" alt="..." />
          </div>
          <div class="carousel-item">
            <img src="./image/ultramar.png" class="imag" alt="..." />
          </div>
          <div class="carousel-item">
            <img src="./image/hasty-market.png" class="imag" alt="..." />
          </div>
        </div>
        <button
          class="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleControls"
          data-bs-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="false"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button
          class="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleControls"
          data-bs-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="false"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  );
}

export default Carousl;
