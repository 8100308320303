import React from "react";
import "./PlaceoppCont.css";

function PlaceoppCont() {
  return (
    <div>
      <section className="fp-section">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-12 poimg">
              <img
                className="freeplacements"
                src="./image/free-placements-1.jpg"
                alt="freeplacements"
              />
            </div>
            <div className="col-md-6 col-sm-12 freeplacementcont">
              <h1>Free Placement</h1>
              <div className="fpunderline"></div>
              <p>
                Our free placement program is based on eligibility, if your
                location passes the site-survey. Apply for a free ATM placement
                with 24/7 Support and Service and increase traffic to your site
                without paying a penny!
              </p>
            </div>
            <div className="col-md-6 col-sm-12 poimg-mbl">
              <img
                className="freeplacements"
                src="./image/free-placements-1.jpg"
                alt="freeplacements"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="fp-section">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-12 freeplacementcont">
              <h1>Rent And Lease To Own</h1>
              <div className="fpunderline"></div>
              <p>
                You can also apply for a month to month rent or lease to own
                package, at minimum rates take advantage of claiming full
                surcharge and having your own ATM.
              </p>
            </div>
            <div className="col-md-6 col-sm-12 poimgm">
              <img
                className="rentlease"
                src="./image/rent-and-lease-1.jpg"
                alt="rentlease"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="fp-section">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-12 poimg">
              <img
                className="freeplacements"
                src="./image/surcharge-sharing.jpg"
                alt="freeplacements"
              />
            </div>
            <div className="col-md-6 col-sm-12 freeplacementcont">
              <h1>Surcharge Split Options</h1>
              <div className="fpunderline"></div>
              <p>
                Our free placement program is based on eligibility, if your
                location passes the site-survey. Apply for a free ATM placement
                with 24/7 Support and Service and increase traffic to your site
                without paying anything!
              </p>
            </div>
            <div className="col-md-6 col-sm-12 poimg-mbl">
              <img
                className="freeplacements"
                src="./image/surcharge-sharing.jpg"
                alt="freeplacements"
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default PlaceoppCont;
