import React, { Component } from 'react'
import Footer from '../Footer'
import TransprosBanner from '../TransprosBanner'
import TransprosInfo from '../TransprosInfo'
import TransprosTag from '../TransprosTag'
import TransprosTitle from '../TransprosTitle'

class TransactionProcessing extends Component {
    render() {
        return (
            <div>
                <TransprosTag />
                <TransprosTitle />
                <TransprosBanner />
                <TransprosInfo />
                <Footer />
            </div>
        )
    }
}

export default TransactionProcessing