import React from "react";
import "./EqusuppTitle.css";

function EqusuppTitle() {
  return (
    <div>
      <section className="equsupp-title-section">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6 col-sm-12 equsupp-title-cont">
              <h2>EQUIPMENT & SUPPLIES</h2>
              <h4>
                For a trusted supplies of ATM Equipment its MBE ATM at your
                service
              </h4>
              <p>
                The formula for satisfied ATM customer service is simply
                managing responsiveness with innovation. At MBE ATM we give
                positive response to the needs of our clients by leading ATM
                equipment, software and hardware. Our trusted alliances with the
                white label ATM’s in North America and on-going regional
                alliances has made us industry leaders in ATM sales in Canada.
              </p>
            </div>
            <div className="col-md-6 col-sm-12 equsupp-title-imag">
              <img
                className="equipmentsupplies"
                src="./image/equipment-supplies.jpg"
                alt="equipmentsupplies"
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default EqusuppTitle;
