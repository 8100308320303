import React, { Component } from 'react'
import ContactSec from '../ContactSec';
import ContactTag from '../ContactTag';
import Footer from '../Footer';

class Contact extends Component {
    render() {
        return (
            <div>
                <ContactTag />
                <ContactSec />
                <Footer />
            </div>
        )
    }
}

export default Contact;