import React from 'react'
import { Link } from 'react-router-dom'
import './AboutTag.css'

function AboutTag() {
    return (
        <div>
            <section>
                <div className='container-fluid tag-section'>
                    <div className='row'>
                        <div className='col-sm-12 col-md-12 tags-cont'>
                            <p><Link className='gohome' to='/'>Home</Link> / About Us</p>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default AboutTag
