import React, { Component } from 'react'
import AboutCompany from '../AboutCompany';
import AboutTag from '../AboutTag';
import AboutTitle from '../AboutTitle';
import Footer from '../Footer';


class About extends Component {
    render() {
        return (
            <div>
                <AboutTag />
                <AboutTitle />
                <AboutCompany />
                <Footer />
            </div>
        )
    }
}

export default About;