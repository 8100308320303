import React from "react";
import "./ServiceSec.css";

function ServiceSec() {
  return (
    <div>
      <section className="service-section">
        <div className="container">
          <div className="row serv">
            <div className="col-md-4 colcontw">
              <img src="./image/rent.png" className="service-logo" alt="rent" />
              <h2>RENT</h2>
              <div className="sub-underline mb-3"></div>
              <p>
                Have an ATM in your store on rental at minimum rates. It’s your
                opportunity to claim full Surcharge.
              </p>
            </div>
            <div className="col-md-4 colcontc">
              <img
                src="./image/purchase.png"
                className="service-logo"
                alt="purchase"
              />
              <h2>PURCHASE</h2>
              <div className="sub-underline mb-3"></div>
              <p>
                For your ATM purchases go for the latest through MBE ATM. It’s a
                direct distributor of Hysoung and Triton with the lowest and the
                most convenient rates.
              </p>
            </div>
            <div className="col-md-4 colcontw">
              <img
                src="./image/free-placement.png"
                className="service-logo"
                alt="free-placement"
              />
              <h2>RENT</h2>
              <div className="sub-underline mb-3"></div>
              <p>
                Free placement offer is always available with absolutely no
                investment. Take the best advantage of the increasing revenue
                through surcharge split.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default ServiceSec;
