import React, { Component } from 'react'
import Footer from '../Footer'
import MaintenBanner from '../MaintenBanner'
import MaintenTag from '../MaintenTag'
import MaintenTitle from '../MaintenTitle'
import MaintenWarranty from '../MaintenWarranty'

class Maintenance extends Component {
    render() {
        return (
            <div>
                <MaintenTag />
                <MaintenTitle />
                <MaintenBanner />
                <MaintenWarranty />
                <Footer />
            </div>
        )
    }
}

export default Maintenance