import React from "react";
import "./navbar.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";

function Navbar() {
  return (
    <nav class="container navbar navbar-expand-lg navbar-light bg-white">
      <div class="container-fluid">
        <Link to="/" class="navbar-brand">
          <img src="./image/logo.png" alt="navlogo" />
        </Link>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav mb-2 mb-lg-0">
            <li class="nav-item">
              <Link class="nav-link" aria-current="page" to="/">
                Home
              </Link>
            </li>
            <li class="nav-item">
              <Link class="nav-link" to="/about">
                About Us
              </Link>
            </li>
            <li class="nav-item dropdown">
              <Link
                class="nav-link"
                to="/"
                // id="navbarDropdown"
                // role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Services{" "}
                <FontAwesomeIcon className="ddicon" icon={faAngleDown} />
              </Link>
              <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                <li>
                  <Link to="/placement-opportunities" class="dropdown-item">
                    Placement Opportunities
                  </Link>
                </li>
                <li>
                  <Link to="/purchase-options" class="dropdown-item">
                    Purchase Options
                  </Link>
                </li>
                <li>
                  <Link to="/placement-modes" class="dropdown-item">
                    Placement Modes
                  </Link>
                </li>
                <li>
                  <Link to="/maintenance" class="dropdown-item">
                    Maintenance
                  </Link>
                </li>
                <li>
                  <Link to="/cash-management" class="dropdown-item">
                    Cash Management
                  </Link>
                </li>
                <li>
                  <Link to="/transaction-processing" class="dropdown-item">
                    Transaction Processing
                  </Link>
                </li>
                <li>
                  <Link to="/equipment-supplies" class="dropdown-item">
                    Equipment & Supplies
                  </Link>
                </li>
                <li>
                  <Link to="/telecommunicatuon" class="dropdown-item" href="/">
                    Telecommunicatuon
                  </Link>
                </li>
                <li>
                  <Link to="/target-marketing" class="dropdown-item" href="/">
                    Target Marketing
                  </Link>
                </li>
              </ul>
            </li>
            <li class="nav-item">
              <Link to="/contact" class="nav-link">
                Contact Us
              </Link>
            </li>
          </ul>
          <form class="d-flex">
            <button class="btn" type="submit">
              <a
                href="https://cashwave.online/"
                rel="noreferrer"
                target="_blank"
                className="mllink"
              >
                Member Login
              </a>
            </button>
          </form>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
