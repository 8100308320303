import React from "react";
import "./MaintenTitle.css";

function MaintenTitle() {
  return (
    <div>
      <section className="mainten-section">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6 col-sm-12 mainten-title-cont">
              <h2>MAINTENANCE</h2>
              <h4>Achieving Professionalism through consistency & Hard work</h4>
              <p>
                In partnership with MBE ATM, leave all concerns of maintenance,
                ATM repair and consistent follow-ups to our expert team.
              </p>
              <p>
                Apart from the time management in ATM repairs and engineering
                works our team will also perform preventative maintenance for
                smooth running in future. We develop a relationship of trust
                with our clients. Upon successful deployment of an MBE ATM, it’s
                our guarantee that our team will perform instant maintenance and
                highly professional checks
              </p>
            </div>
            <div className="col-md-6 col-sm-12 mainten-title-imag">
                <img className='maintenance' src='./image/Maintenance.jpg' alt='maintenance' />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default MaintenTitle;
