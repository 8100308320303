import React, { Component } from 'react'
import PrchoptContent from '../PrchoptContent'
import PrchoptTag from '../PrchoptTag'
import Footer from '../Footer'

class PurchaseOptions extends Component {
    render() {
        return (
            <div>
                <PrchoptTag />
                <PrchoptContent />
                <Footer />
            </div>
        )
    }
}

export default PurchaseOptions