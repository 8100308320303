import React from 'react'
import './MaintenBanner.css'
import { Link } from 'react-router-dom'

function MaintenBanner() {
    return (
        <div>
            <section className='mainten-banner-section'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-md-6 col-sm-12 banner-heading'>
                            <h1>Get the service of a trusted partner for your business</h1>
                        </div>
                        <div className='col-md-6 col-sm-12 banner-button'>
                            <Link to='/'><button className='banner-btn'>Contact Us</button></Link>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default MaintenBanner
