import React from "react";
import "./PlaceAtm.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlayCircle } from "@fortawesome/free-solid-svg-icons";

function PlaceAtm() {
  return (
    <div>
      <section className="placeatm-section">
        <div className="container headings">
          <div className="row">
            <div className="col-md-12 col-sm-12 hedng">
              <h1>Why Place an</h1>
            </div>
            <div className="col-md-12 col-sm-12 sub-hedng">
              <h1>MBE ATM at your location</h1>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <img
                src="./image/my-story-tutor-photo.png"
                className="placeatmimg"
                alt="..."
              />
            </div>
            <div className="col-md-6 col-sm-12 placeatm-cont">
              <ul>
                <li>
                  <div className="flex text-6xl">
                    <FontAwesomeIcon
                      size="2x"
                      className="licon"
                      icon={faPlayCircle}
                    />
                  </div>
                  <p>
                    <strong>Increase traffic</strong> to your business
                  </p>
                </li>
                <li>
                  <div className="flex text-6xl">
                    <FontAwesomeIcon
                      size="2x"
                      className="licon"
                      icon={faPlayCircle}
                    />
                  </div>
                  <p>
                    <strong>Free placement</strong> with highly flexible lease
                    and rent options
                  </p>
                </li>
                <li>
                  <div className="flex text-6xl">
                    <FontAwesomeIcon
                      size="2x"
                      className="licon"
                      icon={faPlayCircle}
                    />
                  </div>
                  <p>
                    <strong>24/7 tech support</strong> and service by licensed
                    ATM Team
                  </p>
                </li>
                <li>
                  <div className="flex text-6xl">
                    <FontAwesomeIcon
                      size="2x"
                      className="licon"
                      icon={faPlayCircle}
                    />
                  </div>
                  <p>
                    A Convenient paperless <strong>Sign-up</strong>
                  </p>
                </li>
              </ul>
              <div className="row innercol">
                <div className="col-md-6 innercol-content">
                  <h3>257,413</h3>
                  <p>Number of Transactions</p>
                </div>
                <div className="col-md-6 innercol-content">
                  <h3>Over 20 Million</h3>
                  <p>Withdraws</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default PlaceAtm;
