import React, { Component } from 'react'
import EqusuppBanner from '../EqusuppBanner'
import EqusuppInfo from '../EqusuppInfo'
import EqusuppTag from '../EqusuppTag'
import EqusuppTitle from '../EqusuppTitle'
import Footer from '../Footer'

class EquipmentSupplies extends Component {
    render() {
        return (
            <div>
                <EqusuppTag />
                <EqusuppTitle />
                <EqusuppBanner />
                <EqusuppInfo />
                <Footer />
            </div>
        )
    }
}

export default EquipmentSupplies