import React from "react";
import "./TrgtmrktInfo.css";

function TrgtmrktInfo() {
  return (
    <div>
      <section className="trgtmrkt-section">
        <div className="trgtmrkt-fluid">
          <div className="row">
            <div className="col-md-12 col-sm-12 trgtmrkt-cont">
              <h3>
                IMAPCTFUL BRADNING STARTEGIES FOR POSITIVE IMPACTS ON YOUR
                DAY-TO-DAY OPERATIONS.
              </h3>
              <h6>ATM RECEIPTS COUPONS:</h6>
              <p>
                Coupons with your ATM receipts is a highly effective marketing
                strategy. This is far more effective than door to door flyers
                and brochures as it saves time, money and energy as well. This
                marketing strategy will definitely make you visit more and more
                as it is in the interest of the customers. Contact us today to
                find out how it works and how you can get coupons on your ATM
                receipts.
              </p>
              <h6>SCREEN ADVERTS ON ATMs:</h6>
              <p>
                Screen advertising is a very common tele-marketing or digital
                marketing strategy. Linked up with customer’s ATM, it is more
                effective as the focus is entirely on the screen, and you never
                know what offer you can avail next. It displays special features
                and promos for people walking by. Our expert team can generate
                for your ATM a special marketing plan that will maximize the
                benefits of your usage with MBE ATM. We can also take care of
                the hardest part of marketing, i.e. getting customer attention,
                if you believe in MBE ATM, we can help you capitalize on our
                commitments.
              </p>
              <h6>
                ATM Solutions tailored according to your needs inclusive of
                Target marketing- CONTACT MBE ATM TODAY!
              </h6>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default TrgtmrktInfo;
