import React, { Component } from 'react'
import Footer from '../Footer'
import PlaceoppCont from '../PlaceoppCont'
import PlaceoppTag from '../PlaceoppTag'

class PlacementOpportunities extends Component {
    render() {
        return (
            <div>
                <PlaceoppTag />
                <PlaceoppCont />
                <Footer />
            </div>
        )
    }
}

export default PlacementOpportunities