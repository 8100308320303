import React, { Component } from "react";
import AtmSaleRent from "../AtmSaleRent";
import Footer from "../Footer";
import FreePlacement from "../FreePlacement";
import HeroHome from "../HeroHome";
import PlaceAtm from "../PlaceAtm";
import ServiceSec from "../ServiceSec";
import Carousl from "../Carousl";
import './Home.css';  



class Home extends Component {
  render() {
    return (
      <div>
        <HeroHome />
        <FreePlacement />
        <ServiceSec />
        <PlaceAtm />
        <Carousl />
        <AtmSaleRent />
        <div className='salerent-cont'>
        <h2>Need help? Ready to Help you with Whatever you Need</h2>
        <h1><a href='tel:+1- 866-667-1377' className='numlink'>+1- 866-667-1377</a></h1>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Home;
