import React from "react";
import { Link } from "react-router-dom";
import './PrchoptTag.css'

function PrchOpt() {
  return (
    <div>
      <section>
        <div className="container-fluid prchop-tag-section">
          <div className="row">
            <div className="col-sm-12 col-md-12 prchop-tags-cont">
              <p>
                <Link className="gohome" to="/">
                  Home
                </Link>{" "}
                / Purchase Options
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default PrchOpt;
