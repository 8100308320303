import React from "react";
import './CashmanagTitle.css'

function CashmanagTitle() {
  return (
    <div>
      <section className="cashmanage-title-section">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6 col-sm-12 cashmanage-title-cont">
              <h2>CASH MANAGEMENT</h2>
              <h4>Giving Output While Sharing Responsibilities</h4>
              <p>
                We deal in Cash management while taking care of many sensitive
                and responsible aspects. If you are thinking to invest with MBE
                ATM, leave your cash management concerns to us. MBE ATM money
                management can represent the costliest aspects of your monthly
                expenses. ATM Cash management offers support and guidance that
                will take you up the ladder of success.
              </p>
              <p>
                Utilization of MBE ATM for the sake of cash management and cash
                loading. A unique feature that reduces the time that needs to be
                dedicated to ATM handling and also allows the client’s focus on
                different business priorities.
              </p>
            </div>
            <div className="col-md-6 col-sm-12 cashmanage-title-imag">
              <img
                className="cashmanagement"
                src="./image/cash management1.jpg"
                alt="cashmanagement"
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default CashmanagTitle;
