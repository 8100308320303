import React from "react";
import "./AboutTitle.css";

function AboutTitle() {
  return (
    <div>
      <section className="about-title-section">
        <div className="container-fluid about-title-content">
          <div className="row">
            <div className="col-md-6 col-sm-12 titleimage">
              <img
                className="imgtitle"
                src="./image/my-story-tutor-photo.png"
                alt="about_title_image"
              />
            </div>
            <div className="col-md-6 col-sm-12 title-content">
              <h1>MBE ATM</h1>
              <div className="sunderline mb-3"></div>
              <p>
                The company MBE ATM is working all over the vast country Canada
                with approximately 700 machines. The company is now processing
                under Cash wave for best and the most reliable services. The aim
                is to increase Customer revenue and make business more
                appealing. Ultimately guaranteeing customer boost and customer
                satisfaction.
              </p>
              <div className="row innertitle">
                <div className="col-md-6 col-sm-12 innertitle-content">
                    <img className='inerimg' src='./image/satisfaction.png' alt='satisfaction' />
                </div>
                <div className="col-md-6 col-sm-12 innertitle-content">
                <img className='inerimg' src='./image/700-atm.png' alt='700_atm' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default AboutTitle;
