import React from "react";
import "./TransprosInfo.css";

function TransprosInfo() {
  return (
    <div>
      <section className="transpros-section">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 col-sm-12 transpros-cont">
              <h3>
                MBE ATM CREATING NEW WAVES OF CHANGE IN TRANSACTION PROCESSING
              </h3>
              <p>
                We at MBE ATM do understand the key importance of uptime at your
                ATM. To ensure maximum capability, MBE ATM is highly associated
                with the switch provider. We always make sure that our ATM is
                processing at the maximum capability.
              </p>
              <p>
                We monitor your ATM performance in real time, being an
                independent ATM switch through direct data feed grasped from the
                switch. MBE ATM in no time can detect any irregularities in
                transaction processing.
              </p>
              <p>
                Easy deployment of MBE ATM at your business will be a value
                addition to your business services. High performance ATMs that
                are durable with excellent customer service, reliability in
                transaction processing and real-time performance monitoring.
              </p>
              <h6>
                IT WAS NEVER SO EASY TO MAKE MONEY WITH AN ATM—PLEASE CALL TODAY
              </h6>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default TransprosInfo;
