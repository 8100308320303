import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";
import "./HeroHome.css";

function HeroHome() {
  return (
    <div>
      <section className="herosection">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <img src="./image/atm.png" className="heroimg" alt="..." />
              <img
                src="./image/my-story-tutor-photo.png"
                className="heroimg2"
                alt="..."
              />
            </div>
            <div className="col-md-8 herocont">
              <h3>Make Your</h3>
              <h1>
                BUSINESS LOOK
                <br />
                MORE APPEALING
              </h1>
              <div className="underline mb-3"></div>
              <p>
                MBE ATM is a company with immense amount of experience in the
                ATM industry. The company promotes store owners for the sake of
                increasing revenue through free ATM Placement with zero
                investments. The company is working everyday with different
                businesses to grow as well as establish decent relationship and
                best service deliverables.
              </p>
              <Link to="/contact">
                <button className="herobtn">
                  Request Online
                  <FontAwesomeIcon className="bnicon" icon={faUser} />
                </button>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
export default HeroHome;
